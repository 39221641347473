import React, { useState } from "react";
import Search from "./components/Search.js";
import Results from "./components/Results.js";
import Recommendations from "./components/Recommendations.js";
import Popup from "./components/Popup";
import MovieDetails from "./components/MovieDetails";

import axios from "axios";

function App() {
  
  const [state, setState] = useState({
    s: "", // The search string
    results: [], // The results from the initial search
    popup: {}, // The movie to be displayed in the popup
    movieDetails: [], // Details of the selected movie (Director, Writer etc...),
    fullMovieDetails: [], // Full API response for the selected movie
    moviesByDirector: [], // Movies by the Diretor
    moviesByWriter: [], // Movies by the Writer
    moviesByCinematographer: [], // Movies by the cinematographer
    moviesByComposer: [], // Movies by the composer
    moviesByLeadActor: [], // Movies featuring the leading actor
    moviesBySupportingActor: [] // Movies featuring the supporting actor
  });

  const apikey = '902cec564338c2cfee10aaacef81033c';

  // Make the API Call
  const search = (e) => {
    if (e.key === "Enter") {
      axios('https://api.themoviedb.org/3/search/movie?api_key=' + apikey + '&query=' + state.s).then(({ data }) => {
        let results = data.results;
        setState((prevState) => {
          return { ...prevState, 
            results: results, 
            moviesByDirector: [], 
            moviesByWriter: [], 
            moviesByCinematographer: [],
            moviesByComposer: []
          };
        });
      });
    }
  };

  // Handle the input from the searchbar
  const handleInput = (e) => {

    let s = e.target.value;
    setState((prevState) => {
      return { ...prevState, s: s };
    });
    
  };


  // Get full data for selected movie
  const getFullData = (movie) => {
    
    // Get the full movie details
    axios('https://api.themoviedb.org/3/movie/' + movie.id + '?api_key=' + apikey).then(({ data }) => {
      let movieData = data;
    
      setState((prevState) => {
        return { ...prevState, fullMovieDetails: movieData };
      });

    });

    axios('https://api.themoviedb.org/3/movie/' + movie.id + '/credits?api_key=' + apikey).then(({ data }) => {
      let movieData = data;
      
      getCrewDetails(movieData, movie.title);

      setState((prevState) => {
        return { ...prevState, results: [] };
      });

    });

  };

  // Get Crew Details
  function getCrewDetails (movieData, movieTitle) {

    var director = '';
    var directorID = '';
    var writer = '';
    var writerID = '';
    var cinematographer = '';
    var cinematographerID = '';
    var composer = '';
    var composerID = '';

    var writerFound = false;
    var composerFound = false;

    // Get Crew Roles
    for (var i = 0; i < movieData['crew'].length; i++) {

      let crewMember = movieData['crew'][i];

      if ( crewMember['job'] === 'Director' ) {
        director = crewMember['name'];
        directorID = crewMember['id'];
      }

      if ((crewMember['job'] === 'Writer' || crewMember['job'] === 'Screenplay') && writerFound === false) {
        writer = crewMember['name'];
        writerID = crewMember['id'];
        writerFound = true;
      }

      if ( crewMember['job'] === 'Director of Photography' ) {
        cinematographer = crewMember['name'];
        cinematographerID = crewMember['id'];
      }

      if ( crewMember['job'] === 'Original Music Composer' && composerFound === false ) {
        composer = crewMember['name'];
        composerID = crewMember['id'];
        composerFound = true;
      }

    }

    // Get Leading Cast
    var leadingRole = movieData['cast'][0]['name'];
    var leadingRoleID = movieData['cast'][0]['id'];

    var supportingRole = movieData['cast'][1]['name'];
    var supportingRoleID = movieData['cast'][1]['id'];

    getMoviesByDirector(directorID, movieTitle);
    getMoviesByWriter(writerID, movieTitle);
    getMoviesByCinematographer(cinematographerID, movieTitle);
    getMoviesByComposer(composerID, movieTitle);

    getMoviesByLeadActor(leadingRoleID, movieTitle);
    getMoviesBySupportingActor(supportingRoleID, movieTitle);
  
    setState((prevState) => {
      return { ...prevState, movieDetails: [director, writer, cinematographer, composer, leadingRole, supportingRole] };
    });

  }

   // Get movies by director
  function getMoviesByDirector(directorID, movieTitle) {

    axios('https://api.themoviedb.org/3/person/' + directorID + '/movie_credits?api_key=' + apikey).then(({ data }) => {
      
      let movies = data;
      let moviesByDirector = [];
      
      for (var i = 0; i < movies['crew'].length; i++) {
        let movie = movies['crew'][i];
        if( movie['job'] === 'Director' ) {
          if( movie['vote_average'] > 7 && movie['title'] !== movieTitle) {
            moviesByDirector.push(movie);
          }
        }
      }

      moviesByDirector = moviesByDirector.slice(0, 9);

      setState((prevState) => {
        return { ...prevState, moviesByDirector: moviesByDirector };
      });

    });

  }

  // Get movies by writer
  function getMoviesByWriter(writerID, movieTitle) {
    
    axios('https://api.themoviedb.org/3/person/' + writerID + '/movie_credits?api_key=' + apikey).then(({ data }) => {
      
      let movies = data;
      let moviesByWriter = [];

      for (var i = 0; i < movies['crew'].length; i++) {
        let movie = movies['crew'][i];
        if( movie['job'] === 'Screenplay' ) {
          if( movie['vote_average'] > 6 && movie['title'] !== movieTitle) {
            moviesByWriter.push(movie);
          }
        }
      }

      moviesByWriter = moviesByWriter.slice(0, 9);

      setState((prevState) => {
        return { ...prevState, moviesByWriter: moviesByWriter };
      });

    });

  }

  // Get movies by writer
  function getMoviesByCinematographer(cinematographerID, movieTitle) {
  
    axios('https://api.themoviedb.org/3/person/' + cinematographerID + '/movie_credits?api_key=' + apikey).then(({ data }) => {
      
      let movies = data;
      let moviesByCinematographer= [];
  
      for (var i = 0; i < movies['crew'].length; i++) {
        let movie = movies['crew'][i];
        if( movie['job'] === 'Director of Photography' ) {
          if( movie['vote_average'] > 7 && movie['title'] !== movieTitle) {
            moviesByCinematographer.push(movie);
          }
        }
      }

      moviesByCinematographer = moviesByCinematographer.slice(0, 9);
  
      setState((prevState) => {
        return { ...prevState, moviesByCinematographer: moviesByCinematographer };
      });
  
    });
  
  }

// Get movies by composer
function getMoviesByComposer(composerID, movieTitle) {

  axios('https://api.themoviedb.org/3/person/' + composerID + '/movie_credits?api_key=' + apikey).then(({ data }) => {
    
    let movies = data;
    let moviesByComposer = [];
    
    for (var i = 0; i < movies['crew'].length; i++) {
      let movie = movies['crew'][i];
      if( movie['job'] === 'Original Music Composer' ) {
        if( movie['vote_average'] > 7 && movie['title'] !== movieTitle) {
          moviesByComposer.push(movie);
        }
      }
    }

    moviesByComposer = moviesByComposer.slice(0, 9);

    setState((prevState) => {
      return { ...prevState, moviesByComposer: moviesByComposer };
    });

  });

}

// Get movies by Lead Actor
function getMoviesByLeadActor(actorID, movieTitle) {

  axios('https://api.themoviedb.org/3/person/' + actorID + '/movie_credits?api_key=' + apikey).then(({ data }) => {
    
    let movies = data;
    let moviesByActor = [];
    
    for (var i = 0; i < movies['cast'].length; i++) {
      let movie = movies['cast'][i];
      if( movie['vote_average'] > 6.5 && movie['title'] !== movieTitle) {
        moviesByActor.push(movie);
      }
    }

    moviesByActor = moviesByActor.slice(0, 9);

    setState((prevState) => {
      return { ...prevState, moviesByLeadActor: moviesByActor };
    });

  });

}


// Get movies by Lead Actor
function getMoviesBySupportingActor(actorID, movieTitle) {

  axios('https://api.themoviedb.org/3/person/' + actorID + '/movie_credits?api_key=' + apikey).then(({ data }) => {
    
    let movies = data;
    let moviesByActor = [];
    
    for (var i = 0; i < movies['cast'].length; i++) {
      let movie = movies['cast'][i];
      if( movie['vote_average'] > 7 && movie['title'] !== movieTitle) {
        moviesByActor.push(movie);
      }
    }

    moviesByActor = moviesByActor.slice(0, 9);

    setState((prevState) => {
      return { ...prevState, moviesBySupportingActor: moviesByActor };
    });

  });

}

function displayPopup (movieData) {

  setState((prevState) => {
    return { ...prevState, popup: movieData };
  });

}

function hidePopup () {

  setState((prevState) => {
    return { ...prevState, popup: {} };
  });

}

  return (
    <div className="App">
      <header>
        <h1>Reel It In</h1>
        <p>Reel It In is an app that will recommend movies to you based on ones that you already like! Enter the name of a movie below to get some recommendations...</p>
      </header>

      <main>
        <Search handleInput={handleInput} search={search} />
        <Results
          results={state.results}
          getFullData={getFullData}
        />

        <MovieDetails movie={state.fullMovieDetails}/>

        <Recommendations 
          movieDetails={state.movieDetails}
          fullMovieDetails={state.fullMovieDetails}
          moviesByDirector={state.moviesByDirector}
          moviesByWriter={state.moviesByWriter}
          moviesByCinematographer={state.moviesByCinematographer}
          moviesByComposer={state.moviesByComposer}
          moviesByLeadActor={state.moviesByLeadActor}
          moviesBySupportingActor={state.moviesBySupportingActor}
          displayPopup={displayPopup}
        />

        <Popup movieData={state.popup} hidePopup={hidePopup}/>
        
      </main>
    </div>
  );
}

export default App;
