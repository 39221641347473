import React from "react";

function MoviesByWriter({ moviesByWriter, movieDetails, fullMovieDetails, displayPopup }) {
  return (
    <section className="movies-by-writer">
      <h2 className="section-title">Did you enjoy the <span className="highlight">Writing?</span></h2>

      <div className="section-description">
        <p>Here are some other movies written by <span className="highlight">{movieDetails[1]}...</span></p>
      </div>

      <div className="movies">
        {moviesByWriter.map((movie) => (
          <div className={movie.poster_path + "-poster recommendation"} key={movie.id} onClick= { () => displayPopup(movie) }>
            {movie.poster_path !== null && (
              <img
                src={"https://image.tmdb.org/t/p/w500" + movie.poster_path}
                alt={movie.title}
              />
            )}

            <div className="overlay-container">
              <h3 className="title">{movie.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default MoviesByWriter;
