import React from "react";

function Popup({ movieData, hidePopup }) {
  console.log(movieData);

  var popupStyle = {
    backgroundImage:
      "url('https://image.tmdb.org/t/p/w1280" + movieData.backdrop_path + "')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  if (movieData.title) {
    return (
      <div className="popup" style={popupStyle}>
        <div className="overlay"></div>
        <div className="popup-contents">
          <div className="left">
            <div className="image-container">
              <img
                src={"https://image.tmdb.org/t/p/w500" + movieData.poster_path}
                alt={movieData.title}
              />
            </div>
          </div>
          <div className="right">
            <h2 className="title">{movieData.title}</h2>
            <div className="description">
              <p>{movieData.overview}</p>
            </div>
            <div className="release">
              <i class="fas fa-calendar-alt"></i>
              <span>{movieData.release_date.substring(0, 4)}</span>
            </div>
            <div className="rating">
              <i class="fas fa-star"></i>
              <span>{movieData.vote_average}</span>
            </div>
            <div className="link">
              <a
                href={"https://www.themoviedb.org/movie/" + movieData.id}
                target="_blank"
                rel="noopener noreferrer"
              >
                More Details
              </a>
            </div>
          </div>

          <div className="close" onClick={hidePopup}>
            <i className="fas fa-times"></i>
          </div>
        </div>
      </div>
    );
  }

  return false;
}

export default Popup;
