import React from "react";

function Result({ result, getFullData }) {

  console.log(result);
  
  if (result.release_date) {

    return (
      <div className="result" onClick = {() => getFullData(result)}>
        <h3 className="title">{result.title} ({result.release_date.substring(0,4)})</h3>
      </div>
    );

  } else {

    return (
      <div className="result" onClick = {() => getFullData(result)}>
        <h3 className="title">{result.title} </h3>
      </div>
    );

  }


  
}

export default Result;
