import React from 'react'
import Result from './Result'

function Results( { results, getFullData } ) {
    return (
        <section className="results">

            {results.map(result => (
                <Result result={result} key={result.id} getFullData={getFullData} />
            ))}

        </section>
    )
}

export default Results
