import React from "react";

function MoviesByComposer({
  moviesByComposer,
  movieDetails,
  fullMovieDetails,
  displayPopup
}) {
  return (
    <section className="movies-by-composer">
      <h2 className="section-title">
        Love the music from{" "}
        <span className="highlight">{fullMovieDetails.title}?</span>
      </h2>

      <div className="section-description">
        <p>
          Take a look at these other movies with Music composed by{" "}
          <span className="highlight">{movieDetails[3]}...</span>
        </p>
      </div>

      <div className="movies">
        {moviesByComposer.map((movie) => (
          <div className={movie.poster_path + "-poster recommendation"} key={movie.id} onClick= { () => displayPopup(movie) }>
            <img
              src={"https://image.tmdb.org/t/p/w500" + movie.poster_path}
              alt={movie.title}
            />
            <div className="overlay-container">
              <h3 className="title">{movie.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default MoviesByComposer;
