import React from "react";

function MoviesBySupportingActor({
  moviesBySupportingActor,
  fullMovieDetails,
  movieDetails,
  displayPopup
}) {
  return (
    <section className="movies-by-director">
      <h2 className="section-title">Enjoy <span className="highlight">{movieDetails[5]}'s</span> performance?</h2>

      <div className="section-description">
        <p>
          Why not check out these other movies featuring <span className="highlight">{movieDetails[5]}...</span>
        </p>
      </div>

      <div className="movies">
        {moviesBySupportingActor.map((movie) => (
          <div className={movie.poster_path + "-poster recommendation"} key={movie.id + "-support"} onClick= { () => displayPopup(movie) }>
            {movie.poster_path !== null && (
              <img
                src={"https://image.tmdb.org/t/p/w500" + movie.poster_path}
                alt={movie.title}
              />
            )}
            <div className="overlay-container">
              <h3 className="title">{movie.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default MoviesBySupportingActor;
