import React from "react";

function MovieDetails({ movie }) {

  if (movie.title) {
    var genres = [];
    for (var i = 0; i < movie.genres.length; i++) {
      genres.push(movie.genres[i]["name"]);
    }

    return (
      <div className="container">
        <section className="movie-details">
          <div className="poster">
            <img
              src={"https://image.tmdb.org/t/p/w500" + movie.poster_path}
              alt={movie.title}
            />
          </div>
          <div className="details">
            <h2 className="title">{movie.title}</h2>
            <p className="plot">{movie.overview}</p>
            <div className="runtime">
              <i class="fas fa-clock"></i>
              <span>{movie.runtime} minutes</span>
            </div>
            <div className="genre">
              <i class="fas fa-film"></i>
              <span>{genres.join(", ")}</span>
            </div>
            <div className="rating">
              <i class="fas fa-star"></i>
              <span>{movie.vote_average}</span>
            </div>
          </div>
        </section>
      </div>
    );
  }

  return false;
}

export default MovieDetails;
