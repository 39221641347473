import React from "react";
import MoviesByDirector from "./MoviesByDirector";
import MoviesByWriter from "./MoviesByWriter";
import MoviesByCinematographer from "./MoviesByCinematographer";
import MoviesByComposer from "./MoviesByComposer";
import MoviesByLeadActor from "./MoviesByLeadActor";
import MoviesBySupportingActor from "./MoviesBySupportingActor";

function Recommendations({
  moviesByDirector,
  moviesByWriter,
  moviesByCinematographer,
  moviesByComposer,
  moviesByLeadActor,
  moviesBySupportingActor,
  movieDetails,
  fullMovieDetails,
  displayPopup,
}) {
  return (
    <div className="container">
      {/* Movies By Director */}
      {moviesByDirector.length > 0 && (
        <MoviesByDirector
          moviesByDirector={moviesByDirector}
          movieDetails={movieDetails}
          fullMovieDetails={fullMovieDetails}
          displayPopup={displayPopup}
        />
      )}

      {/* Movies By Writer */}
      {moviesByWriter.length > 0 && (
        <MoviesByWriter
          moviesByWriter={moviesByWriter}
          movieDetails={movieDetails}
          fullMovieDetails={fullMovieDetails}
          displayPopup={displayPopup}
        />
      )}

      {/* Movies By Cinematographer */}
      {moviesByCinematographer.length > 0 && (
        <MoviesByCinematographer
          moviesByCinematographer={moviesByCinematographer}
          movieDetails={movieDetails}
          fullMovieDetails={fullMovieDetails}
          displayPopup={displayPopup}
        />
      )}

      {/* Movies By Composer */}
      {moviesByComposer.length > 0 && (
        <MoviesByComposer
          moviesByComposer={moviesByComposer}
          movieDetails={movieDetails}
          fullMovieDetails={fullMovieDetails}
          displayPopup={displayPopup}
        />
      )}

      {/* Movies By Lead Actor */}
      {moviesByLeadActor.length > 0 && (
        <MoviesByLeadActor
          moviesByLeadActor={moviesByLeadActor}
          movieDetails={movieDetails}
          fullMovieDetails={fullMovieDetails}
          displayPopup={displayPopup}
        />
      )}

      {/* Movies By Supporting Actor */}
      {moviesBySupportingActor.length > 0 && (
        <MoviesBySupportingActor
          moviesBySupportingActor={moviesBySupportingActor}
          movieDetails={movieDetails}
          fullMovieDetails={fullMovieDetails}
          displayPopup={displayPopup}
        />
      )}
    </div>
  );
}

export default Recommendations;
